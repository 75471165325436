@import './mixins.scss';
@import './overrides.scss';

.completeUploadNotification {
  width: 100%;
  margin-bottom: 0rem;
  max-width: 564px;
}

.bx--data-table-content {
  overflow-x: unset;
}

#available-dataset-tab__panel {
  padding: 0px;
}

#opitmized-dataset-tab__panel {
  padding: 0px;
}

.upload_submit_buttoms_div {
  display: flex;
}

.upload_buttom {
  min-width: 150px;
  //max-width: 160px;
}

.submit_buttom {
  min-width: 150px;
  //max-width: 160px;
  margin-left: 1rem;
}

.bx--file-container {
  margin-top: 10px;
}

.bx--btn-set .bx--btn {
  max-width: 160px;
}

//optimize and available dataset table notification
.deleteSuccessNotification {
  margin-top: 0;
  margin-bottom: 0;
  max-width: none;
}

.bx--inline-notification {
  //max-width: none;
  margin-bottom: 0rem;
}

.bx--file__selected-file {
  //max-width: none;
  width: 100%;
  min-width: 564px;
}

//tab content width
.bx--tabs__nav-link {
  width: auto;
}

.landing_page_loading_icon {
  left: 45%;
  margin-top: 15%;
  position: fixed;
}

//opitmized-dataset-tab
#opitmized-dataset-tab {
  width: auto;
}

// maintenance section
.maintenance_text {
  position: absolute;
  margin-top: 10%;
  left: 23%;
  @include carbon--type-style('productive-heading-04');
}

.maintenance_image {
  margin-top: 15%;
  width: 10%;
  position: absolute;
  left: 45%;
}
