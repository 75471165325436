@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

@import './components/Info/info.scss';
@import './components/TutorialHeader/tutorial-header.scss';
@import './content/LoginPage/login-page.scss';
@import './content/Instruction/instruction-page.scss';
@import './content/Optimizer/optimizer-page.scss';
@import './content/Optimizer/select-module-page.scss';
@import './content/AgreementPage/agreement-page.scss';

.productive-heading-02 {
  @include carbon--type-style('productive-heading-02');
}

.paragraph_styles {
  @include carbon--type-style('body-long-02');
}

.paragraph_styles_justify {
  @include carbon--type-style('body-long-02');
  text-align: justify;
}

.productive-heading-03 {
  @include carbon--type-style('productive-heading-03');
}

.productive-heading-04 {
  @include carbon--type-style('productive-heading-04');
}

.bx--grid--no-gutter {
  padding-left: 5rem;
  padding-right: 5rem;
}

.bx--modal-container {
  width: 40%;
}
