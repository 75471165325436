@import './mixins.scss';
@import './overrides.scss';

.landing-page__illo {
  max-width: 100%;
}

.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
  @include landing-page-background;
}

.instruction-page__heading {
  @include carbon--type-style('productive-heading-04');
  text-align: justify;
}

.landing-page__r2 {
  margin-top: rem(-40px);
}

.landing-page__tab-content {
  padding-top: $layout-02;
  padding-bottom: $layout-02;
  //background: $ui-01;
}

.instruction_section {
  padding-top: $layout-02;
  padding-bottom: $layout-02;
  background: $ui-02;
}

.identify_modules_section {
  padding-top: $layout-02;
  padding-bottom: $layout-02;
  background: $ui-01;
}

.prepare_datasets_section {
  padding-top: $layout-02;
  padding-bottom: $layout-02;
  background: $ui-02;
}

.go_to_optimize_section {
  padding-top: $layout-02;
  padding-bottom: $layout-02;
  background: $ui-01;
}

.transfer_dataset_section {
  padding-top: $layout-02;
  padding-bottom: $layout-02;
  background: $ui-02;
}

.test_module_performance_section {
  padding-top: $layout-02;
  padding-bottom: $layout-02;
  background: $ui-01;
}

.landing-page__subheading {
  @include carbon--type-style('productive-heading-03');
  @include carbon--font-weight('semibold');
}

.landing-page__p {
  @include carbon--type-style('productive-heading-03');
  margin-top: $spacing-06;
  margin-bottom: $spacing-08;
  text-align: justify;

  @include carbon--breakpoint-between((320px + 1), md) {
    max-width: 75%;
  }
}

.landing-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  @include landing-page-background;
}

.landing-page__label {
  @include carbon--type-style('heading-01');
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.bx--snippet--multi {
  min-width: 40rem;
}

.performance_image {
  padding-top: 20px;
  max-width: 600px;
  width: 100%;
}
