.bx--data-table-header {
  background: white;
  padding: 1rem 0 1.5rem 0rem;
}

.select_module_table_content {
  padding-bottom: $layout-02;
}

.select_version_div {
  display: inline-flex;
}

.selection_software_class {
  margin-left: 1rem;
}

.bx--data-table-header__title {
  @include carbon--type-style('productive-heading-03');
}

.back_to_optimizer_link {
  position: relative;
  bottom: 5px;
  left: 5px;
  font-weight: bold;
  font-size: 18px;
}

.opitmize_buttom_class {
  min-width: 160px;
}
