.support_menu {
  color: white;
  min-width: 8rem;
  //font-weight: bold;
  @include carbon--type-style('body-long-02');
  //cursor: pointer;
  //@include carbon--type-style('productive-heading-02');
}

.header--notification {
  position: fixed;
  right: 0%;
  top: 5%;
}

/*
.user_support_menu .bx--header__menu-title {
  padding: 1rem;
  margin-top: -13px;
  min-height: 100%;
  min-width: 8rem;
}
*/

.user_profile_menu .bx--header__menu-title {
  padding: 1rem;
  margin-top: -13px;
  min-height: 100%;
  min-width: 8rem;
}
